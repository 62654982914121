
$("#toggle").click(function() {
  $(this).toggleClass("on");
  $("#menu").slideToggle();
});

$( '#products' ).on( 'click', '.js-search-link', function( event ) {
  var target = $( "#js-product-list-top" );
  if ( target.length ) {
    event.preventDefault();
    $( 'html, body' ).animate( {
      scrollTop: target.offset().top-10
    }, 1000 );
  }
});

// ===== Scroll to Top ==== 
$(window).scroll(function() {
  if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
      $('#return-to-top').fadeIn(200);    // Fade in the arrow
  } else {
      $('#return-to-top').fadeOut(200);   // Else fade out the arrow
  }
});
$('#return-to-top').click(function() {      // When arrow is clicked
  $('body,html').animate({
      scrollTop : 0                       // Scroll to top of body
  }, 500);
});

